import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/common/Home';
import Login from './components/auth/Login';
import SubscriptionSuccess from "./components/SubscriptionSuccess";
import { useUser } from './contexts/UserContext';

function App() {
    const { user, loading } = useUser();

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while user state is being resolved
    }

    return (
        <Router>
            <Routes>
                <Route path="/subscription-success" element={<SubscriptionSuccess />} />
                <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
                <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
            </Routes>
        </Router>
    );
}

export default App;
