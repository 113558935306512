import React, { useEffect, useRef, useState } from 'react';
import { List, ListItem, ListItemText, Avatar, Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MessageList = ({ messages }) => {
    const [isAtBottom, setIsAtBottom] = useState(true);
    const messagesEndRef = useRef(null);
    const chatContainerRef = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
        }
    };

    useEffect(() => {
        if (isAtBottom) {
            scrollToBottom();
        }
    }, [messages, isAtBottom]);

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <Box component={Paper} style={{ maxHeight: '71vh', overflow: 'auto', marginTop: 16 }} ref={chatContainerRef}>
            <List aria-label="List of messages">
                {messages.map((msg, index) => (
                    <ListItem
                        key={index}
                        divider
                        sx={{
                            backgroundColor: msg.audio ? theme.palette.action.hover : 'inherit', // Highlight if the message has TTS audio
                        }}
                    >
                        <ListItemText
                            primary={
                                <Box display="flex" alignItems="center">
                                    {msg.badges.map((badgeUrl, idx) => (
                                        <Avatar
                                            key={idx}
                                            alt="badge"
                                            src={badgeUrl}
                                            sx={{
                                                marginRight: 1,
                                                width: isMobile ? 16 : 24, // Adjust the size of the badge based on the screen size
                                                height: isMobile ? 16 : 24, // Adjust the size of the badge based on the screen size
                                            }}
                                        />
                                    ))}
                                    <Typography variant={isMobile ? "body2" : "body1"} component="span" sx={{ marginRight: 1 }}>
                                        <strong>{msg.username}:</strong>
                                    </Typography>
                                    <Typography variant={isMobile ? "body2" : "body1"} component="span">
                                        <span dangerouslySetInnerHTML={{ __html: msg.message }} />
                                    </Typography>
                                </Box>
                            }
                        />
                    </ListItem>
                ))}
                <div ref={messagesEndRef} />
            </List>
        </Box>
    );
};

export default MessageList;
