// src/components/admin/ViewFeedback.js
import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Box, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from '../../contexts/UserContext';

const ViewFeedback = () => {
    const { user } = useUser();
    const [feedbacks, setFeedbacks] = useState([]);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            const q = query(collection(db, 'feedback'), orderBy('timestamp', 'desc'));
            const querySnapshot = await getDocs(q);
            const feedbackList = await Promise.all(
                querySnapshot.docs.map(async (docSnapshot) => {
                    const feedbackData = docSnapshot.data();
                    const userDoc = await getDoc(doc(db, 'users', feedbackData.userId));
                    const userEmail = userDoc.exists() ? userDoc.data().email : 'Unknown';
                    return { id: docSnapshot.id, ...feedbackData, email: userEmail };
                })
            );
            setFeedbacks(feedbackList);
        };

        fetchFeedbacks();
    }, []);

    const handleDelete = async (feedbackId) => {
        if (window.confirm('Are you sure you want to delete this feedback?')) {
            try {
                await deleteDoc(doc(db, 'feedback', feedbackId));
                setFeedbacks(feedbacks.filter(feedback => feedback.id !== feedbackId));
            } catch (error) {
                console.error('Error deleting feedback:', error);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                User Feedback
            </Typography>
            <List>
                {feedbacks.map(feedback => (
                    <ListItem key={feedback.id}>
                        <ListItemText
                            primary={feedback.feedback}
                            secondary={`User ID: ${feedback.userId} - Email: ${feedback.email} - ${new Date(feedback.timestamp).toLocaleString()}`}
                        />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(feedback.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ViewFeedback;
