// src/components/common/Subscription.js
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../../firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = ({ priceId }) => {
    const [loading, setLoading] = useState(false);

    const handleSubscribe = async () => {
        setLoading(true);

        const user = auth.currentUser;
        if (!user) {
            alert('You need to log in to subscribe');
            setLoading(false);
            return;
        }

        const token = await user.getIdToken();

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/create-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, priceId }),
        });

        if (!response.ok) {
            const error = await response.json();
            console.error('Error creating subscription:', error);
            alert(error.error); // Provide user-friendly feedback
            setLoading(false);
            return;
        }

        const { sessionId } = await response.json();

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
            console.error('Stripe error:', error);
            alert('Stripe error: ' + error.message); // Provide user-friendly feedback
        }

        setLoading(false);
    };


    return (
        <div>
            <Typography variant="h6">Subscribe to Access Premium Features</Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubscribe}
                disabled={loading}
            >
                {loading ? 'Loading...' : 'Subscribe'}
            </Button>
        </div>
    );
};

export default Subscription;
