import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { auth, db } from '../../firebase';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdminChannelRequests = () => {
    const { user } = useUser();
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newChannelName, setNewChannelName] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [currentChannels, setCurrentChannels] = useState([]);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const q = query(collection(db, 'channelRequests'));
                const querySnapshot = await getDocs(q);
                const requestsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setRequests(requestsData);
            } catch (error) {
                console.error('Error fetching requests:', error);
            }
        };

        const fetchCurrentChannels = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_ENDPOINT_HTTPS}/getChannels`
                );
                const data = await response.json();
                setCurrentChannels(data.availableChannels || []);
            } catch (error) {
                console.error('Error fetching current channels:', error);
                setCurrentChannels([]); // Ensure it is set to an array even if an error occurs
            }
        };

        fetchRequests();
        fetchCurrentChannels();
        setLoading(false);
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'channelRequests', id));
            setRequests(requests.filter(request => request.id !== id));
        } catch (error) {
            console.error('Error deleting request:', error);
        }
    };

    const handleAddChannel = async (channelName) => {
        try {
            const token = await user.getIdToken();
            const response = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT_HTTPS}/addChannel`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ token, channelName })
                }
            );

            const responseData = await response.json();
            if (response.ok) {
                setResponseMessage(`Success: ${responseData.message}`);
                setRequests(requests.filter(request => request.channelName !== channelName)); // Remove the added channel from the requests list
                setCurrentChannels(prevChannels => [...prevChannels, channelName]); // Add the new channel to the current channels list
            } else {
                setResponseMessage(`Error: ${responseData.error}`);
            }
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
        }
    };

    const handleDeleteChannel = async (channelName) => {
        try {
            const token = await user.getIdToken();
            const response = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT_HTTPS}/deleteChannel`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ token, channelName })
                }
            );

            const responseData = await response.json();
            if (response.ok) {
                setResponseMessage(`Success: ${responseData.message}`);
                setCurrentChannels(currentChannels.filter(channel => channel !== channelName)); // Remove the channel from the current channels list
            } else {
                setResponseMessage(`Error: ${responseData.error}`);
            }
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
        }
    };

    const handleAddNewChannel = async () => {
        await handleAddChannel(newChannelName);
        setNewChannelName('');
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box mt={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Admin Dashboard</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Current Channels
                            </Typography>
                            <List>
                                {Array.isArray(currentChannels) && currentChannels.length > 0 ? (
                                    currentChannels.map((channel, index) => (
                                        <ListItem key={index} divider>
                                            <ListItemText primary={channel} />
                                            <Button variant="contained" color="secondary" onClick={() => handleDeleteChannel(channel)}>
                                                Delete
                                            </Button>
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="No channels available" />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Channel Requests
                            </Typography>
                            <List>
                                {requests.map((request) => (
                                    <ListItem key={request.id} divider>
                                        <ListItemText
                                            primary={`Channel: ${request.channelName}`}
                                            secondary={`Requested by: ${request.username} at ${new Date(request.timestamp?.toDate()).toLocaleString()}`}
                                        />
                                        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                            <Grid item>
                                                <Button variant="contained" color="primary" onClick={() => handleAddChannel(request.channelName)}>
                                                    Add
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="secondary" onClick={() => handleDelete(request.id)}>
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    {responseMessage && (
                        <Typography color={responseMessage.startsWith('Error') ? 'error' : 'primary'}>
                            {responseMessage}
                        </Typography>
                    )}
                    <Box mt={4} width="100%">
                        <Typography variant="h6" gutterBottom>
                            Add New Channel
                        </Typography>
                        <TextField
                            label="Channel Name"
                            value={newChannelName}
                            onChange={(e) => setNewChannelName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleAddNewChannel}>
                            Add Channel
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default AdminChannelRequests;
