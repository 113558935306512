import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useUser } from '../../contexts/UserContext';

const ChannelSelector = ({ channels, currentChannel, setCurrentChannel, socket, setMessages, setQueue, setCurrentChannelId, freeChannel }) => {
    const { role } = useUser();
    const isUser = role === 'user' || role === 'pending';

    const handleChannelChange = (event) => {
        const newChannel = event.target.value;
        if (currentChannel) {
            socket.current.emit('leaveChannel', currentChannel);
        }

        setCurrentChannel(newChannel);
        setMessages([]);
        setQueue([]);
        socket.current.emit('joinChannel', newChannel, (channelId) => {
            setCurrentChannelId(channelId);
        });
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="channel-select-label">Select Channel</InputLabel>
            <Select
                labelId="channel-select-label"
                value={currentChannel}
                onChange={handleChannelChange}
                inputProps={{ 'aria-label': 'Select a channel to join' }}
            >
                {channels.map((channel, index) => (
                    <MenuItem
                        key={index}
                        value={channel}
                        disabled={isUser && channel !== freeChannel}
                        style={channel === freeChannel ? { fontWeight: 'bold', color: 'green' } : {}}
                    >
                        {channel === freeChannel ? `${channel} (Free)` : channel}
                    </MenuItem>
                ))}
            </Select>
            {isUser && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                    You can only join the free channel: <strong>{freeChannel}</strong>
                </Typography>
            )}
        </FormControl>
    );
};

export default ChannelSelector;
