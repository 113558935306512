// src/contexts/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);

                const userDocRef = doc(db, 'users', user.uid);

                // Listen for changes to the user's document in Firestore
                const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
                    const userData = docSnapshot.data();
                    setRole(userData?.role || 'user');
                    setLoading(false); // Loading complete after first fetch
                });

                return () => unsubscribeSnapshot();
            } else {
                setUser(null);
                setRole('');
                setLoading(false); // Set loading to false if no user is logged in
            }
        });

        return () => unsubscribeAuth();
    }, []);

    if (loading) {
        // Return null or a loading indicator while the user and role are being fetched
        return <div>Loading...</div>;
    }

    return (
        <UserContext.Provider value={{ user, role, loading }}>
            {children}
        </UserContext.Provider>
    );
};
