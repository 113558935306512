import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const useSocket = (user, ENDPOINT) => {
    const [channels, setChannels] = useState([]);
    const [queue, setQueue] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [role, setRole] = useState('');
    const [freeChannel, setFreeChannel] = useState('');
    const socket = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!user) return;

            const token = await user.getIdToken();
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            const userData = userDoc.data();

            setRole(userData?.role);
            setIsAdmin(userData?.role === 'admin');
            setIsSubscribed(userData?.role === 'subscriber');

            if (!socket.current) {
                socket.current = socketIOClient(ENDPOINT, {
                    auth: { token },
                    transports: ['websocket', 'polling'],
                    secure: true,
                });

                socket.current.on('availableChannels', (channels) => {
                    setChannels(channels);
                });

                socket.current.on('updateChannels', (updatedChannels) => {
                    setChannels(updatedChannels);
                });

                socket.current.on('updateFreeChannel', (freeChannel) => {
                    setFreeChannel(freeChannel);
                });

                socket.current.on('newMessage', (data) => {
                    setQueue(prevQueue => [...prevQueue, data]);
                });
            }

            // Fetch the free channel from the server
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/getFreeChannel`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setFreeChannel(data.freeChannel);
            } catch (error) {
                console.error('Error fetching free channel:', error);
            }
        };

        const checkSubscriptionStatus = async () => {
            if (!user) return;

            const token = await user.getIdToken();
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/subscription-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();
            setIsSubscribed(data.subscriptionStatus === 'active');
        };

        fetchUserData();

        // Set up periodic check for subscription status every 5 minutes
        const intervalId = setInterval(checkSubscriptionStatus, 300000); // 5 minutes in milliseconds

        return () => {
            if (socket.current) {
                socket.current.disconnect();
                socket.current = null;
            }
            clearInterval(intervalId);
        };
    }, [user, ENDPOINT]);

    return { channels, queue, isAdmin, setQueue, socket, isSubscribed, role, freeChannel };
};

export default useSocket;
