import React, { useState } from 'react';
import { auth, db } from '../../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { Box, Button, TextField, Typography } from '@mui/material';
import "./Login.css";
import TTSImage from '../../assets/TTSIMAGE.png'; // Import the image

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isRegister, setIsRegister] = useState(false);

    const handleAuth = async () => {
        try {
            if (isRegister) {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Set the user's role to "user" in Firestore
                await setDoc(doc(db, 'users', user.uid), {
                    email: user.email,
                    role: 'user',
                    subscriptionId: null,
                    stripeCustomerId: null,
                });
            } else {
                await signInWithEmailAndPassword(auth, email, password);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Box mt={2} role="form" id={"page"}>
            <Typography variant="h6" color="blue" gutterBottom>
                Disclaimer: This is a beta version of the app. Expect changes and report any issues or feedback.
            </Typography>
            <img src={TTSImage} alt={"Text-to-speech logo"} id={"loginimg"} />
            <Typography variant="h5" gutterBottom id={"title"}>
                {isRegister ? 'Register' : 'Login'}
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                required
                inputProps={{ 'aria-label': 'Email address' }}
            />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                required
                inputProps={{ 'aria-label': 'Password' }}
            />
            <Button variant="contained" color="primary" onClick={handleAuth} fullWidth aria-label={isRegister ? 'Register' : 'Login'}>
                {isRegister ? 'Register' : 'Login'}
            </Button>
            <Button color="secondary" onClick={() => setIsRegister(!isRegister)} fullWidth aria-label={isRegister ? 'Already have an account? Login' : "Don't have an account? Register"}>
                {isRegister ? 'Already have an account? Login' : "Don't have an account? Register"}
            </Button>
        </Box>
    );
};

export default Login;
