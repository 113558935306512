// src/components/common/AccountHandling.js
import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import { db } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import Subscription from './Subscription';

const AccountHandling = ({ priceId }) => {
    const { user, role } = useUser(); // Access the role from UserContext
    const [loading, setLoading] = useState(true);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    useEffect(() => {
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);

            // Listen for changes to the user's subscription status in Firestore
            const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
                const userData = docSnapshot.data();
                setSubscriptionStatus(userData?.subscriptionStatus);
                setLoading(false); // Stop loading once data is fetched
            });

            return () => unsubscribeSnapshot();
        }
    }, [user]);

    const handleCancelSubscription = async () => {
        setLoading(true);
        const token = await user.getIdToken();

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/cancel-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });

        const data = await response.json();
        if (data.success) {
            setSubscriptionStatus('cancelling');
        }

        setLoading(false);
    };

    const handleRevokeCancelSubscription = async () => {
        setLoading(true);
        const token = await user.getIdToken();

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/revoke-cancel-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });

        const data = await response.json();
        if (data.success) {
            setSubscriptionStatus('active');
        } else {
            console.error('Error revoking cancellation:', data.error);
        }

        setLoading(false);
    };

    if (loading) {
        return <CircularProgress />;
    }

    // If the user is an admin, do not show subscription-related content
    if (role === 'admin') {
        return null;
    }

    return (
        <Box>
            {subscriptionStatus === 'active' || subscriptionStatus === 'cancelling' ? (
                <Box>
                    <Typography variant="body1">
                        {subscriptionStatus === 'active'
                            ? 'Your subscription is active.'
                            : 'Your subscription is scheduled to be canceled at the end of the billing period.'}
                    </Typography>
                    {subscriptionStatus === 'cancelling' && (
                        <Button variant="contained" color="warning" onClick={handleRevokeCancelSubscription}>
                            Revoke Cancellation
                        </Button>
                    )}
                    {subscriptionStatus === 'active' && (
                        <Button variant="contained" color="error" onClick={handleCancelSubscription}>
                            Cancel Subscription
                        </Button>
                    )}
                </Box>
            ) : (
                <Box>
                    <Typography variant="body1" color="error">
                        {(subscriptionStatus === "none" || subscriptionStatus === undefined || subscriptionStatus === null ) ? null : "Your subscription has expired. Please subscribe to continue using premium features."}
                    </Typography>
                    <Subscription priceId={priceId} />
                </Box>
            )}
        </Box>
    );
};

export default AccountHandling;
