// src/components/common/Home.js
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import useSocket from '../../hooks/useSocket';
import MessageList from '../chat/MessageList';
import ChannelRequestForm from '../chat/ChannelRequestForm';
import AdminChannelRequests from '../admin/AdminChannelRequests';
import ChannelSelector from '../chat/ChannelSelector';
import AuthActions from './AuthActions';
import ChatControls from '../chat/Controls';
import ChatInput from '../chat/ChatInput';
import Subscription from './Subscription';
import AccountHandling from './AccountHandling';
import FeedbackDialog from './FeedbackDialog';
import ViewFeedback from '../admin/ViewFeedback';

export const ENDPOINT_SOCKET = process.env.REACT_APP_API_ENDPOINT_SOCKET;

const Home = () => {
    const { user, role } = useUser();
    const { channels, queue, isAdmin, setQueue, socket, isSubscribed, freeChannel } = useSocket(user, ENDPOINT_SOCKET);
    const [messages, setMessages] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [volume, setVolume] = useState(1.0);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [pan, setPan] = useState(0); // Center by default
    const [currentChannel, setCurrentChannel] = useState('');
    const [twitchUser, setTwitchUser] = useState(null);
    const [twitchToken, setTwitchToken] = useState(null);
    const [currentChannelId, setCurrentChannelId] = useState('');
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const audioRef = useRef(null);
    const audioContextRef = useRef(null);
    const gainNodeRef = useRef(null);
    const panNodeRef = useRef(null);
    const messageInputRef = useRef(null);

    const playAudio = useCallback((message) => {
        if (!message.audio) {
            // If there is no audio data, just add the message to the list without playing any audio
            setMessages(prevMessages => [...prevMessages, message]);
            setQueue(prevQueue => prevQueue.slice(1)); // Remove the message from the queue
            return;
        }

        setIsPlaying(true);
        const audio = new Audio(`data:audio/mp3;base64,${message.audio}`);
        audio.volume = volume;
        audio.playbackRate = playbackRate;

        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            gainNodeRef.current = audioContextRef.current.createGain();
            panNodeRef.current = audioContextRef.current.createStereoPanner();
            gainNodeRef.current.connect(panNodeRef.current);
            panNodeRef.current.connect(audioContextRef.current.destination);
        }

        const source = audioContextRef.current.createMediaElementSource(audio);
        source.connect(gainNodeRef.current);
        gainNodeRef.current.gain.value = volume;
        panNodeRef.current.pan.value = pan;

        audioRef.current = audio;

        // Add message to the list of messages being read
        setMessages(prevMessages => [...prevMessages, message]);

        audio.play();
        audio.onended = () => {
            setIsPlaying(false);
            setQueue(prevQueue => prevQueue.slice(1));
        };
    }, [volume, playbackRate, pan, setQueue]);


    useEffect(() => {
        if (queue.length > 0 && !isPlaying && !isPaused) {
            playAudio(queue[0]);
        }
    }, [queue, isPlaying, isPaused, playAudio]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
        if (gainNodeRef.current) {
            gainNodeRef.current.gain.value = volume;
        }
    }, [volume]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    useEffect(() => {
        if (panNodeRef.current) {
            panNodeRef.current.pan.value = pan;
        }
    }, [pan]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (currentChannel && socket.current) {
                socket.current.emit('leaveChannel', currentChannel);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [currentChannel, socket]);

    const togglePause = () => {
        if (isPaused) {
            setIsPaused(false);
            if (audioRef.current) {
                audioRef.current.play();
            }
        } else {
            setIsPaused(true);
            if (audioRef.current) {
                audioRef.current.pause();
            }
        }
    };

    const clearChat = () => {
        setMessages([]);
    };

    const renderChannelRequestForm = () => {
        if (role === 'admin') {
            return <AdminChannelRequests />;
        }
        if (role === 'subscriber') {
            return <ChannelRequestForm />;
        }
        return (
            <div>
                <Typography variant="h6" color="error">
                    You need to subscribe to request a channel.
                </Typography>
                <Subscription priceId={process.env.REACT_APP_STRIPE_PRICE_ID} />
            </div>
        );
    };

    const handleFeedbackOpen = () => {
        setFeedbackOpen(true);
    };

    const handleFeedbackClose = () => {
        setFeedbackOpen(false);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" color="blue" gutterBottom>
                    Disclaimer: This is a beta version of the app. Expect changes and report any issues or feedback.
                </Typography>
                <Button variant="outlined" onClick={handleFeedbackOpen} style={{ marginRight: '10px' }}>
                    Send Feedback
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Text to Speech Twitch Chat
                </Typography>
                <Box>
                    <AccountHandling priceId={process.env.REACT_APP_STRIPE_PRICE_ID} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <ChannelSelector
                    channels={channels}
                    currentChannel={currentChannel}
                    setCurrentChannel={setCurrentChannel}
                    socket={socket}
                    setMessages={setMessages}
                    setQueue={setQueue}
                    setCurrentChannelId={setCurrentChannelId}
                    freeChannel={freeChannel}
                    role={role}
                />
                <AuthActions
                    setTwitchToken={setTwitchToken}
                    setTwitchUser={setTwitchUser}
                    twitchUser={twitchUser}
                    TWITCH_CLIENT_ID={process.env.REACT_APP_TWITCH_CLIENT_ID}
                    REDIRECT_URI={process.env.REACT_APP_TWITCH_REDIRECT_URI}
                    socket={socket}
                    currentChannel={currentChannel}
                    setCurrentChannel={setCurrentChannel}
                    setMessages={setMessages}
                    setQueue={setQueue}
                />
            </Box>
            {renderChannelRequestForm()}
            {role === 'admin' && <ViewFeedback />}
            <ChatControls
                isPaused={isPaused}
                togglePause={togglePause}
                volume={volume}
                setVolume={setVolume}
                playbackRate={playbackRate}
                setPlaybackRate={setPlaybackRate}
                clearChat={clearChat}
                pan={pan}
                setPan={setPan}
            />
            <MessageList messages={messages} />
            {twitchUser && currentChannel !== '' && (
                <ChatInput
                    twitchToken={twitchToken}
                    currentChannelId={currentChannelId}
                    twitchUser={twitchUser}
                    messageInputRef={messageInputRef}
                />
            )}
            <FeedbackDialog open={feedbackOpen} handleClose={handleFeedbackClose} />
        </Container>
    );
};

export default Home;
