// src/components/common/AuthActions.js
import React from 'react';
import { Box, Button } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

const AuthActions = ({ setTwitchToken, setTwitchUser, twitchUser, socket, currentChannel, setCurrentChannel, setMessages, setQueue }) => {
    const handleLogout = async () => {
        if (currentChannel) {
            socket.current.emit('leaveChannel', currentChannel);
            setCurrentChannel('');
            setMessages([]);
            setQueue([]);
        }

        try {
            await signOut(auth);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleTwitchLogin = () => {
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const url = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITCH_REDIRECT_URI}&scope=chat:edit+chat:read+user:write:chat`;

        const popup = window.open(url, 'Twitch Login', `width=${width},height=${height},top=${top},left=${left}`);

        const interval = setInterval(() => {
            try {
                if (!popup || popup.closed !== false) {
                    clearInterval(interval);
                    return;
                }

                const hash = popup.location.hash;
                if (hash) {
                    const params = new URLSearchParams(hash.substring(1));
                    const accessToken = params.get('access_token');
                    if (accessToken) {
                        setTwitchToken(accessToken);
                        fetch('https://api.twitch.tv/helix/users', {
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Client-Id': process.env.REACT_APP_TWITCH_CLIENT_ID
                            }
                        })
                            .then(response => response.json())
                            .then(data => setTwitchUser(data.data[0]))
                            .finally(() => popup.close());
                        clearInterval(interval);
                    }
                }
            } catch (e) {
                console.log('Waiting for Twitch login...');
            }
        }, 100);
    };

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Button variant="contained" color="secondary" onClick={handleLogout} sx={{ ml: 2 }}>
                Logout
            </Button>
            {!twitchUser && (
                <Button variant="contained" color="secondary" onClick={handleTwitchLogin} sx={{ ml: 2 }}>
                    Login with Twitch
                </Button>
            )}
        </Box>
    );
};

export default AuthActions;
