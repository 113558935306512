// src/components/ChatInput.js
import React from 'react';
import { Box, Button, TextField } from '@mui/material';

const ChatInput = ({ twitchToken, currentChannelId, twitchUser, messageInputRef }) => {
    const sendMessage = () => {
        if (twitchToken && currentChannelId && messageInputRef.current) {
            const message = messageInputRef.current.value;
            if (message.trim() !== '') {
                fetch('https://api.twitch.tv/helix/chat/messages', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${twitchToken}`,
                        'Client-Id': process.env.REACT_APP_TWITCH_CLIENT_ID,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        broadcaster_id: currentChannelId,
                        message: message,
                        sender_id: twitchUser.id
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to send message');
                        }
                        messageInputRef.current.value = '';
                    })
                    .catch(error => {
                        console.error('Error sending message:', error);
                    });
            }
        }
    };

    return (
        <Box
            mt={2}
            display="flex"
            alignItems="center"
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            p={2}
            bgcolor="white"
        >
            <TextField
                variant="outlined"
                label="Message"
                fullWidth
                inputRef={messageInputRef}
                inputProps={{ 'aria-label': 'Type your message' }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        sendMessage();
                    }
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={sendMessage}
                sx={{ ml: 2 }}
            >
                Send
            </Button>
        </Box>
    );
};

export default ChatInput;
