// src/components/common/FeedbackDialog.js
import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useUser } from '../../contexts/UserContext';

const FeedbackDialog = ({ open, handleClose }) => {
    const { user } = useUser();
    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        if (feedback.trim() === '') {
            setError('Feedback cannot be empty.');
            return;
        }
        try {
            const feedbackRef = doc(db, 'feedback', `${user.uid}_${Date.now()}`);
            await setDoc(feedbackRef, {
                userId: user.uid,
                feedback: feedback,
                timestamp: new Date().toISOString(),
            });
            setFeedback('');
            handleClose();
        } catch (error) {
            setError('Failed to send feedback.');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Send Feedback</DialogTitle>
            <DialogContent>
                <Typography color="textSecondary">Your feedback helps us improve the app.</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Your Feedback"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    error={!!error}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Cancel</Button>
                <Button onClick={handleSubmit} color="primary">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackDialog;
