import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';

const SubscriptionSuccess = () => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const isFetchingRef = useRef(false); // Ref to track if a fetch is in progress

    useEffect(() => {
        const completeSubscription = async () => {
            const urlParams = new URLSearchParams(location.search);
            const sessionId = urlParams.get('session_id');

            if (!sessionId || isFetchingRef.current) {
                return;
            }

            isFetchingRef.current = true; // Set the ref to true before making the request

            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT_HTTPS}/complete-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sessionId }),
            });

            if (response.ok) {
                const { subscriptionId } = await response.json();
                console.log('Subscription completed successfully', subscriptionId);
                setIsCompleted(true);
                navigate('/');
            } else {
                const error = await response.json();
                console.error('Error completing subscription:', error);
                setErrorMessage(error.message || 'An error occurred while completing the subscription.');
            }

            isFetchingRef.current = false; // Reset the ref after the request completes
        };

        completeSubscription();
    }, [location.search, navigate]);

    return (
        <div>
            {errorMessage ? (
                <Typography variant="h5" color="error">
                    {errorMessage}
                </Typography>
            ) : (
                <>
                    <Typography variant="h5">Completing your subscription...</Typography>
                    <CircularProgress />
                </>
            )}
        </div>
    );
};

export default SubscriptionSuccess;
