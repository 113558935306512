// src/components/chat/ChannelRequestForm.js
import React, { useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Box, Button, TextField, Typography } from '@mui/material';

const ChannelRequestForm = () => {
    const { user, role } = useUser(); // Use the UserContext to access user and role
    const [channelName, setChannelName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!user) {
            setError('You must be logged in to request a channel.');
            return;
        }

        try {
            await addDoc(collection(db, 'channelRequests'), {
                userId: user.uid,
                username: user.displayName || user.email,
                channelName,
                timestamp: serverTimestamp(),
            });
            setSuccess('Channel request submitted successfully!');
            setChannelName('');
        } catch (error) {
            setError('Failed to submit channel request.');
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} mt={2} role="form">
            <Typography variant="h6" gutterBottom>
                Request a Channel
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
            <TextField
                label="Channel Name"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
                fullWidth
                margin="normal"
                required
                inputProps={{ 'aria-label': 'Channel name' }}
            />
            <Button type="submit" variant="contained" color="primary" aria-label="Submit channel request">
                Submit Request
            </Button>
        </Box>
    );
};

export default ChannelRequestForm;
