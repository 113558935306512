import React from 'react';
import { Box, Button, Slider, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

const Controls = ({ isPaused, togglePause, volume, setVolume, playbackRate, setPlaybackRate, clearChat, pan, setPan }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const speedMarks = [
        { value: 0.5, label: '0.5x' },
        { value: 1, label: '1x' },
        { value: 2, label: '2x' }
    ];

    const panMarks = [
        { value: -1, label: 'Left' },
        { value: 0, label: 'Center' },
        { value: 1, label: 'Right' }
    ];

    const handlePanChange = (event, newValue) => {
        if (Math.abs(newValue) < 0.1) {
            newValue = 0;
        }
        setPan(newValue);
    };

    return (
        <Box mt={2}>
            {isMobile ? (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Controls</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={togglePause}  aria-label={isPaused ? 'Resume playback' : 'Pause playback'}>
                                    {isPaused ? 'Resume' : 'Pause'}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary" onClick={clearChat}  aria-label="Clear chat">
                                    Clear Chat
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom>Volume</Typography>
                                <Slider
                                    value={volume}
                                    onChange={(e, newValue) => setVolume(newValue)}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    valueLabelDisplay="auto"
                                    aria-label="Volume control"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom>Speed</Typography>
                                <Slider
                                    value={playbackRate}
                                    onChange={(e, newValue) => setPlaybackRate(newValue)}
                                    min={0.5}
                                    max={2}
                                    step={0.1}
                                    valueLabelDisplay="auto"
                                    marks={speedMarks}
                                    aria-label="Playback speed control"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom>Pan</Typography>
                                <Slider
                                    value={pan}
                                    onChange={handlePanChange}
                                    min={-1}
                                    max={1}
                                    step={0.01}
                                    valueLabelDisplay="auto"
                                    marks={panMarks}
                                    aria-label="Audio pan control"
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Button variant="contained" color="primary" onClick={togglePause} sx={{ mr: 2 }} aria-label={isPaused ? 'Resume playback' : 'Pause playback'}>
                            {isPaused ? 'Resume' : 'Pause'}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={clearChat} sx={{ mr: 2 }} aria-label="Clear chat">
                            Clear Chat
                        </Button>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                        <Box mr={2} flexGrow={1}>
                            <Typography gutterBottom>Volume</Typography>
                            <Slider
                                value={volume}
                                onChange={(e, newValue) => setVolume(newValue)}
                                min={0}
                                max={1}
                                step={0.01}
                                valueLabelDisplay="auto"
                                aria-label="Volume control"
                            />
                        </Box>
                        <Box mr={2} flexGrow={1}>
                            <Typography gutterBottom>Speed</Typography>
                            <Slider
                                value={playbackRate}
                                onChange={(e, newValue) => setPlaybackRate(newValue)}
                                min={0.5}
                                max={2}
                                step={0.1}
                                valueLabelDisplay="auto"
                                marks={speedMarks}
                                aria-label="Playback speed control"
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <Typography gutterBottom>Pan</Typography>
                            <Slider
                                value={pan}
                                onChange={handlePanChange}
                                min={-1}
                                max={1}
                                step={0.01}
                                valueLabelDisplay="auto"
                                marks={panMarks}
                                aria-label="Audio pan control"
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Controls;
